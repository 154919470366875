import React, {useState, useEffect} from "react";
import axios from "axios";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import whiteLabel from "../assets/whitelabelConfig";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions";
import { getAPIConfig } from "../common/utils";
import ENDPOINTS from "../common/endpoints";
import "../Styles/style.css";

function LoginForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [expiration, setExpiration] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isChecked) {
      setExpiration(30);
    } else {
      setExpiration(1);
    }
  }, [isChecked]); // Depend on isChecked state


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // const encryptedPassword = encryptPassword(password);
      const requestBody = {
        email: email,
        password: password,
        expiration: expiration,
      };
      const configuration = getAPIConfig();

      const response = await axios.post(ENDPOINTS.AUTH.login, requestBody, configuration);
      const data = response.data;
      localStorage.setItem("token", data.token);
      props.setAuthenticated(true);
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch(setUser(data.user));
      const url = new URL(window.location.href);
      const redirectUrl = url.searchParams.get("redirect");
      navigate(redirectUrl || "/assets");
    } catch (error) {
      try {
        setErrorMessage(error.response.data.message);
      } catch (error) {
        setErrorMessage("Please try again after sometime.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid vh-100 d-flex flex-column">
        <div className="row vh-100">
          {/* BG Image (8 columns) */}
          <div className="col-lg-8 col-md-8 position-relative p-0">
            <img
              src={whiteLabel.BG_login}
              className="img-fluid h-100 w-100"
              alt="Background_login"
            />
            <div className="position-absolute bottom-0 start-0 w-100 bg-dark opacity-75 text-white p-3 pb-5 px-4">
              <div className="news-caption">
                <h2 className="caption-title">
                  <b>MKTG.AI</b>
                </h2>
                <h2 className="caption-title">
                  <strong>
                    A creative-first marketing software designed for the c-suite
                    to visualize all consumer touch points and monitor marketing
                    effectiveness.
                  </strong>
                </h2>
              </div>
            </div>
          </div>

          {/* Login Part (4 columns) */}
          <div className="col-lg-4 col-md-4 d-flex justify-content-center align-items-center login lg-mt-5">
            <div className="text-white mb-4 mb-md-0">
              <div className="d-flex align-items-center">
                <img
                  src={whiteLabel.MktgAILogo}
                  className="card-img-top"
                  alt="MKTGAI_logo"
                  style={{height: "80px", width: "200px" }}
                />
                  <i className="bi bi-box-arrow-in-right mx-2 custom-icon-size"></i>
              </div>
              <div className="mb-5 pb-5 mb-md-0 pb-md-0">
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="mb-2 text-white">
                      Email Address<span className="text-danger">*</span>{" "}
                    </div>
                    <div className="mb-2">
                      <input
                        name="email"
                        type="email"
                        className="form-control inputs border-0 text-white"
                        id="exampleInputEmail1"
                        placeholder="Your email address is your username"
                        aria-describedby="emailHelp"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="mb-2 text-white">
                      Password<span className="text-danger">*</span>
                    </div>
                    <div className="col-md mb-2 position-relative">
                      <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control inputs border-0 text-white"
                        placeholder="Password"
                        id="exampleInputPassword1"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i
                        className={`bi ${
                          showPassword ? "bi-eye" : "bi-eye-slash"
                        }`}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={toggleShowPassword}
                      />
                    </div>
                  </div>

                  <div className="form-check text-start my-3 text-white">
                    <input
                      name="expiration"
                      className="form-check-input border-0"
                      type="checkbox"
                      checked={isChecked}
                      id="flexCheckDefault"
                      onChange={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault">
                      Remember me
                    </label>
                  </div>
                  <div className="form-check text-start my-3 text-white">
                    <input
                      name="expiration"
                      className="form-check-input border-0"
                      type="checkbox"
                      checked={isCheck}
                      id="flexCheckDefault"
                      onChange={() => {
                        setIsCheck(!isCheck);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault">
                      Log me in automatically
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isLoading}>
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"></span>
                    )}
                    Login
                  </button>
                </form>
                </div>
            </div>
          </div>
        </div>
        <div className="position-fixed w-100" style={{bottom:"0px"}}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default LoginForm;
