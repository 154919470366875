import React, {useState, useEffect, useMemo} from "react";
import "../../../Styles/style.css";
import HubView from "./HubView/Hubview";
import ListView from "./ListView/Listview";
import Spinner from "../../../components/spinner";
import axios from "axios";
import DatePicker from "react-datepicker";
import AssetFilters from "../elements/AssetFilters";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {useSelector, useDispatch} from "react-redux";
import {
  setStartDate,
  setEndDate,
  setHeatmapOn,
  setSizeFactor,
  resetFilters,
} from "../../../redux/actions";
import {
  categories,
  status,
  platforms,
  disabledPlatforms,
  types,
  performance,
  // ETFs,
  // Tiers,
  sizes,
} from "../utils/constants";
import {
  formatDateForBackend,
  parseDateString,
  getDefaultRequestBody,
  getShadeInfo,
} from "../utils/utils";
import useCustomDispatch from "../utils/useCustomDispatch";
import {getAPIConfig} from "../../../common/utils";
import ENDPOINTS from "../../../common/endpoints";

function Assets(props) {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    selectedCategory,
    selectedPlatform,
    selectedType,
    selectedStatus,
    selectedPerformance,
    heatmapOn,
    sizeFactor,
    viewType,
    searchText,
  } = useSelector((state) => state.assets);
  const {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
  } = useCustomDispatch();
  const [hoveredImage, setHoveredImage] = useState(null);
  const [mediaPlatformData, setMediaPlatformData] = useState([]);
  const [renderedItemsCount, setRenderedItemsCount] = useState(
    mediaPlatformData.current_count
  );
  const [platformCounts, setPlatformCounts] = useState({});
  const [categoryCounts, setCategoryCounts] = useState({});
  const [typeCounts, setTypeCounts] = useState({});
  const [statusCounts, setStatusCounts] = useState({});
  const [performanceCounts, setPerformanceCounts] = useState({});
  const [totalCounts, setTotalCounts] = useState({});
  const [totalFilterCount, setTotalFilterCount] = useState(0);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [cancelTokenAssetCountSource, setCancelTokenAssetCountSource] =
    useState(null);
  const apiConfig = useMemo(() => getAPIConfig(), []);
  const [isMoreData, setIsMoreData] = useState(true);

  const fetchDataFromBackend = async (requestBody = {}) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    setIsLoading(true);
    setErrormsg("");
    setMediaPlatformData([]);
    const config = {
      headers: apiConfig.headers,
      cancelToken: source.token,
    };

    const addToRequestBody = (key, value) => {
      if (
        value &&
        value.length > 0 &&
        value.some((item) => item.trim() !== "")
      ) {
        requestBody[key] = value.filter((item) => item.trim() !== "");
      }
    };

    if (startDate && endDate) {
      requestBody.start_date = formatDateForBackend(startDate);
      requestBody.end_date = formatDateForBackend(endDate);
    }

    addToRequestBody("category", selectedCategory);
    addToRequestBody("channel", selectedPlatform);
    addToRequestBody("type", selectedType);
    addToRequestBody("status", selectedStatus);
    if (selectedPerformance && selectedPerformance.length > 0) {
      requestBody.performance_value = selectedPerformance;
    }

    requestBody.semantic_search_text = searchText;

    try {
      const response = await axios.post(
        ENDPOINTS.ASSET.getAssets,
        requestBody,
        config
      );
      const data = response.data || {};
      const {filters = {}} = data;
      setIsMoreData(response.data?.is_more_data);
      setMediaPlatformData(data);
      setRenderedItemsCount(data.current_count);
      if (filters.start_date)
        handleStartDateChange(parseDateString(filters.start_date));
      if (filters.end_date)
        handleEndDateChange(parseDateString(filters.end_date));
      handleCategoryChange(filters.category);
      handlePlatformsChange(filters.channel);
      handleStatusChange(filters.status);
      handleTypesChange(filters.types);
      handlePerformanceChange(filters.performance_value);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        setIsLoading(false);
        setErrormsg(
          "Try refreshing/Logging out, if still facing the same issue contact Admin"
        );
      }
    }
  };

  const getAssetCount = async (requestBody = {}, total_counts = false) => {
    if (cancelTokenAssetCountSource) {
      cancelTokenAssetCountSource.cancel(
        "Operation canceled due to new request."
      );
    }

    const source = axios.CancelToken.source();
    setCancelTokenAssetCountSource(source);
    const addToRequestBody = (key, value) => {
      if (
        value &&
        value.length > 0 &&
        value.some((item) => item.trim() !== "")
      ) {
        requestBody[key] = value.filter((item) => item.trim() !== "");
      }
    };

    if (startDate && endDate) {
      requestBody.start_date = formatDateForBackend(startDate);
      requestBody.end_date = formatDateForBackend(endDate);
    }

    addToRequestBody("category", selectedCategory);
    addToRequestBody("channel", selectedPlatform);
    addToRequestBody("type", selectedType);
    addToRequestBody("status", selectedStatus);
    if (selectedPerformance && selectedPerformance.length > 0) {
      requestBody.performance_value = selectedPerformance;
    }

    requestBody.semantic_search_text = searchText;
    requestBody.total_counts = total_counts;
    const config = {
      headers: apiConfig.headers,
      cancelToken: source.token,
    };

    try {
      const response = await axios.post(
        ENDPOINTS.ASSET.getCount,
        requestBody,
        config
      );
      const filtered_counts = response.data.filtered_counts || {};
      const total_counts = response.data.total_counts || {};

      // Setting filters
      setCategoryCounts({
        Organic: filtered_counts.organic,
        Paid: filtered_counts.paid,
      });

      setTotalFilterCount(filtered_counts?.total_filtered_count || 0);
      setPlatformCounts({
        Instagram: filtered_counts.instagram,
        Facebook: filtered_counts.facebook,
        Linkedin: filtered_counts.linkedin,
        Reddit: filtered_counts.reddit,
        TradeDesk: filtered_counts.tradedesk,
        Non_Digital: filtered_counts.non_digital,
        Google_Analytics: filtered_counts.google_analytics,
        Google_Ads: filtered_counts.google_ads,
        Youtube: filtered_counts.youtube,
      });

      setTypeCounts({
        Image: filtered_counts.image,
        Video: filtered_counts.video,
        Audio: filtered_counts.audio,
        PDF: filtered_counts.pdf,
        Blog: filtered_counts.blog,
        Document: filtered_counts.document,
        Webpage: filtered_counts.webpage,
        Link: filtered_counts.link,
        Text: filtered_counts.text,
        Album: filtered_counts.album
      });

      setStatusCounts({
        Active: filtered_counts.active,
        Available: filtered_counts.available,
        Paused: filtered_counts.paused,
        Removed: filtered_counts.removed,
        Archived: filtered_counts.archived,
        Draft: filtered_counts.draft,
        Published: filtered_counts.published,
        Enabled: filtered_counts.enabled,
        Public: filtered_counts.public,
        Private: filtered_counts.private,
        Unlisted: filtered_counts.unlisted
      });

      setPerformanceCounts({
        Very_Low: filtered_counts.very_low,
        Low: filtered_counts.low,
        Average: filtered_counts.average,
        Good: filtered_counts.good,
        Excellent: filtered_counts.excellent,
        Unknown: filtered_counts.unknown,
      });

      if (total_counts && Object.keys(total_counts).length > 0) {
        const totalCountsData = {
          Organic: total_counts.organic,
          Paid: total_counts.paid,
          Instagram: total_counts.instagram,
          Facebook: total_counts.facebook,
          Linkedin: total_counts.linkedin,
          Reddit: total_counts.reddit,
          TradeDesk: total_counts.tradedesk,
          Non_Digital: total_counts.non_digital,
          Google_Analytics: total_counts.google_analytics,
          Google_Ads: total_counts.google_ads,
          Youtube: total_counts.youtube,
          Image: total_counts.image,
          Video: total_counts.video,
          Audio: total_counts.audio,
          PDF: total_counts.pdf,
          Blog: total_counts.blog,
          Document: total_counts.document,
          Webpage: total_counts.webpage,
          Link: total_counts.link,
          Text: total_counts.text,
          Album: total_counts.album,
          Active: total_counts.active,
          Available: total_counts.available,
          Public: total_counts.public,
          Private: total_counts.private,
          Unlisted: total_counts.unlisted,
          Paused: total_counts.paused,
          Removed: total_counts.removed,
          Archived: total_counts.archived,
          Draft: total_counts.draft,
          Published: total_counts.published,
          Enabled: total_counts.enabled,
          Very_Low: total_counts.very_low,
          Low: total_counts.low,
          Average: total_counts.average,
          Good: total_counts.good,
          Excellent: total_counts.excellent,
          Unknown: total_counts.unknown,
        };
        setTotalCounts(totalCountsData);
        sessionStorage.setItem("total_counts", JSON.stringify(totalCountsData));
      } else {
        setTotalCounts(JSON.parse(sessionStorage.getItem("total_counts")));
      }

    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log("Request canceled", error.message);
      } else {
        console.error({error});
      }
    }
  };

  useEffect(() => {
    const defaultRequestBody = getDefaultRequestBody();
    fetchDataFromBackend(defaultRequestBody);
    getAssetCount(defaultRequestBody, true);
    // eslint-disable-next-line
  }, [resetFilters, props.semanticSearchText]);

  const handleImageHover = (img) => {
    setHoveredImage(img);
  };

  const handleImageHoverOut = () => {
    setHoveredImage(null);
  };

  const handleRangeChange = (event) => {
    dispatch(setSizeFactor(parseInt(sizes[event.target.value])));
  };

  const handleToggle = () => {
    dispatch(setHeatmapOn(!heatmapOn));
  };

  // Function to handle start date change
  const handleStartDateChange = (date) => {
    // Store the date in local time zone
    if (date) {
      dispatch(setStartDate(date.toISOString()));
    }
  };

  // Function to handle end date change
  const handleEndDateChange = (date) => {
    // Store the date in local time zone
    if (date) {
      dispatch(setEndDate(date.toISOString()));
    }
  };

  const handleResetFilters = () => {
    setIsInitialFetchDone(false);
    dispatch(resetFilters());
  };

  const handleSubmit = () => {
    if (!isInitialFetchDone) {
      const defaultRequestBody = getDefaultRequestBody();
      fetchDataFromBackend(defaultRequestBody);
      getAssetCount(defaultRequestBody);
      setIsInitialFetchDone(true);
    } else {
      fetchDataFromBackend();
      getAssetCount();
    }
  };

  const update_counts = (renderedItemsCount) => {
    setRenderedItemsCount(renderedItemsCount);
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={
          "m-0 p-0 inner-nav position-fixed fixed-top row justify-content-end" +
          (props.isNavbarVisible ? " scrolled-up" : " scrolled-down")
        }
        style={{zIndex: "10"}}>
        {props.isSideBar && <div className="col-3 col-md-3 col-lg-2"></div>}
        <div
          className={`${
            props.isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
          } transition-width p-0`}>
          <nav className="navbar navbar-expand-lg bg-black">
            <div className="container-fluid px-0">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent">
                <div className="container-fluid row px-0 mx-0">
                  <div className="col-12">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {/* Views */}
                      <li className="nav-item mt-1 me-2">
                        <button
                          className="btn btn-black text-white border-secondary py-1"
                          type="button"
                          id="hubViewDropdown"
                          aria-expanded="false">
                          {viewType}
                        </button>
                      </li>
                      {/* Date Picker */}
                      <li className="nav-item mt-1">
                        <div className="d-flex">
                          <div className="startdate mx-2">
                            <DatePicker
                              className="form-control bg-black text-white border-secondary p-1"
                              selected={startDate ? new Date(startDate) : null}
                              onChange={(date) => handleStartDateChange(date)}
                              placeholderText="Start Date"
                              dateFormat="MMM dd, yyyy"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              maxDate={endDate ? new Date(endDate) : new Date()}
                            />
                          </div>
                          <div className="enddate mx-2">
                            <DatePicker
                              className="form-control bg-black border-secondary text-white p-1"
                              selected={endDate ? new Date(endDate) : null}
                              onChange={(date) => handleEndDateChange(date)}
                              placeholderText="End Date"
                              dateFormat="MMM dd, yyyy"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              minDate={startDate ? new Date(startDate) : null}
                            />
                          </div>
                        </div>
                      </li>
                      {/* Reset/Go */}
                      <li className="nav-item mt-1">
                        <button
                          className="btn bg-secondary btn-sm rounded mx-2 px-3 text-white"
                          onClick={handleResetFilters}>
                          Reset
                        </button>
                        <button
                          className="btn bg-primary btn-sm rounded mx-2 px-3 text-white"
                          onClick={handleSubmit}>
                          Go
                        </button>
                      </li>
                      {/* Heatmap */}
                      <li className="nav-item ms-auto mt-2">
                        <div className="d-flex align-items-center text-white rounded-2 border border-secondary ps-2">
                          <label className="me-2">Heat Map</label>
                          <div className="form-check form-switch mt-1">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{cursor: "pointer"}}
                              checked={heatmapOn}
                              onChange={handleToggle}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    <ul className="navbar-nav me-auto align-items-center">
                      {/* Filters */}
                      <li className="nav-item">
                        <AssetFilters
                          selectedCategory={selectedCategory}
                          selectedPlatform={selectedPlatform}
                          selectedType={selectedType}
                          selectedStatus={selectedStatus}
                          selectedPerformance={selectedPerformance}
                          categories={categories}
                          platforms={platforms}
                          types={types}
                          Status={status}
                          performance={performance}
                          categoryCounts={categoryCounts}
                          platformCounts={platformCounts}
                          typeCounts={typeCounts}
                          statusCounts={statusCounts}
                          performanceCounts={performanceCounts}
                          totalCounts={totalCounts}
                          getShadeInfo={getShadeInfo}
                          disabledPlatforms={disabledPlatforms}
                          // ETFs={ETFs}
                          // Tiers={Tiers}
                        />
                      </li>
                      {/* Slider */}
                      <li className="nav-item ms-auto">
                        {viewType === "List View" ? null : (
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="customRange3"
                              className="form-label text-white mb-0 me-2">
                              Display Size
                            </label>
                            <input
                              type="range"
                              className="form-range"
                              style={{width: "70px"}}
                              min="0"
                              max="4"
                              step="1"
                              id="customRange3"
                              value={sizes.indexOf(sizeFactor)}
                              onChange={handleRangeChange}
                            />
                          </div>
                        )}
                      </li>
                    </ul>
                    <div className="col-12">
                      <ul className="navbar-nav me-auto align-items-center">
                        <li className="nav-item ms-auto text-white">
                          {renderedItemsCount && !isLoading
                            ? `Displaying ${renderedItemsCount} of ${totalFilterCount}`
                            : "Displaying 0 of 0 assets"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="hubview-section ps-2 pt-2 pb-2 pe-1">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {errormsg ? (
              <div className="text-white mt-4">
                <h5>
                  Try refreshing/Logging out. If the issue persists, contact
                  Admin.
                </h5>
              </div>
            ) : mediaPlatformData.items?.length === 0 ? (
              <div className="text-white mt-4">
                <h5>No asset found</h5>
              </div>
            ) : (
              mediaPlatformData.items &&
              mediaPlatformData.items.length !== 0 && (
                <>
                {viewType === "Hub View" ? (
                  <HubView
                    sizeFactor={sizeFactor}
                    heatmapOn={heatmapOn}
                    data={mediaPlatformData}
                    handleImageHover={handleImageHover}
                    handleImageHoverOut={handleImageHoverOut}
                    viewType={viewType}
                    hoveredImage={hoveredImage}
                    startDate={startDate}
                    endDate={endDate}
                    selectedCategory={selectedCategory}
                    selectedPlatform={selectedPlatform}
                    selectedType={selectedType}
                    selectedStatus={selectedStatus}
                    selectedPerformance={selectedPerformance}
                    update_counts={update_counts}
                    semanticSearchText={searchText}
                    isMoreData={isMoreData}
                    setIsMoreData={setIsMoreData}
                  />
                ) : (
                  <ListView
                    data={mediaPlatformData}
                    startDate={startDate}
                    endDate={endDate}
                    selectedCategory={selectedCategory}
                  />
                )}
              </>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default Assets;
