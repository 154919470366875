import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { EMPTY_FUNC, EMPTY_OBJECT } from "../../../common/constants/constants";
import InputGroup from "react-bootstrap/InputGroup";

const PerformanceMetricInputField = ({
  label,
  controlId,
  value,
  onChange,
  placeholder,
  name,
  type = "text",
  required,
  disabled,
  step = "0.01",
  labelClassName,
  groupClassName,
  IconComponent: iconComponent,
  validate = EMPTY_FUNC,
  setErrors = EMPTY_FUNC,
  errors = EMPTY_OBJECT,
  showErrroAsTooltip = true,
  ...restProps
}) => {
  const validationErrorMessage = validate(value);
  const hasError = !!validationErrorMessage;

  useEffect(() => {
    if (!validationErrorMessage && errors[controlId]) {
      setErrors((prevState) => {
        const newState = { ...prevState };
        delete newState[controlId];
        return newState;
      });
    }
    if (hasError) {
      setErrors((prevState) => ({
        ...prevState,
        [controlId]: validationErrorMessage,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InputGroup hasValidation tooltip={showErrroAsTooltip}>
      <Form.Group
        {...(groupClassName && { className: groupClassName })}
        {...(controlId && { controlId })}
      >
        {label && (
          <Form.Label
            column
            {...(labelClassName && { className: labelClassName })}
          >
            {label}
            {iconComponent}
          </Form.Label>
        )}
        <Form.Control
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          step={step}
          required={required}
          disabled={disabled}
          isInvalid={!!validationErrorMessage}
          {...restProps}
        />
        {validationErrorMessage && (
          <Form.Control.Feedback type="invalid" tooltip={showErrroAsTooltip}>
            {validationErrorMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </InputGroup>
  );
};

export default PerformanceMetricInputField;
