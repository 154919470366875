import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import whiteLabel from "../../../assets/whitelabelConfig";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, setSearchText } from "../../../redux/actions";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";

const Navbar = ({
  user,
  handleLogout,
  isNavbarVisible,
  toggleSidebar,
  isSideBar,
  onSearch,
  isDetailsPage,
  // isSidebarOpen,
}) => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.assets.searchText);
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    dispatch(setSearchText(event.target.value));
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSearch(searchText);
  };

  const clearSearchText = () => {
    dispatch(setSearchText(""));
  };

  // Function to toggle navbar collapse state
  const toggleNavbarCollapse = () => {
    setNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleLogoutWithFilterClear = () => {
    dispatch(resetFilters());
    handleLogout();
  };

  const navigateToProfile = () => {
    navigate("/profile");  // Navigate to profile page
  };

  return (
    <div className={"position-fixed p-0 m-0 fixed-top justify-content-end " + (isNavbarVisible ? " nav-up" : " nav-down")}>
      {/* <div className={`col-12 col-md-3 col-lg-2 p-0 ${isSideBar ? '' : 'hidden-div'}`}></div> */}
      {/* <div className={`col-12 p-0`} > */}
        <nav className="navbar navbar-expand-lg bg-black border-bottom border-secondary">

          <div className="navbar-brand d-flex align-items-center mx-2">
            <button
              className="btn btn-lg btn-black rounded text-white py-0 mx-1"
              type="button"
              onClick={toggleSidebar}
              >
              <i className="bi bi-layout-sidebar"></i>
            </button>
            <Link to="/assets" className="text-decoration-none">
              <img
                src={user.logo_url ? user.logo_url : whiteLabel.MktgAI_Icon }
                alt="client_logo"
                width="25"
                height="20"
                className="img-fluid rounded-circle me-2 mb-1"
              />
              <span className="text-white">MKTG.AI for {user.client_name}</span>
            </Link>
          </div>

          <button
            className="navbar-toggler p-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbarCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse bg-black" id="navbarText" >
          <ul className="navbar-nav mx-2">
          <li className="nav-item dropdown">
          <form onSubmit={handleSearchSubmit}>
          <div className={`input-group ${isDetailsPage ? "" : "d-none"}`}>
                  <input
                    type="text"
                    className="form-control border-0 outline-0 custom-search-box text-white"
                    placeholder="Enter keyword"
                    value={searchText} 
                    onChange={handleInputChange}
                  />
                  <span className={`btn btn-light text-white border-0 d-flex align-items-center justify-content-center custom-search-button ${searchText ? 'd-block' : 'd-none'}`} onClick={clearSearchText}>
                    <i className="bi bi-x-lg"></i>
                  </span>
                  <button className="btn btn-light text-white border-0 d-flex align-items-center justify-content-center custom-search-button"
                  onClick={handleSearchSubmit}
                  >
                    <i className="bi bi-search" aria-hidden="true"></i>
                  </button>
            </div>
          </form>
            </li>
            </ul>
            <ul className="navbar-nav mx-2 d-flex ms-auto">
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle font-weight-bold text-white py-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i
                    className="bi bi-person-circle fs-4 me-2 "
                    aria-hidden="true"></i>
                  {user.name}
                </button>
                <ul className="dropdown-menu dropdown-menu-end bg-dark mt-1">
                  <li>
                    <button className="dropdown-item text-white"
                     onClick={navigateToProfile}
                    >
                    <i className="bi bi-person-vcard me-2"></i>
                      My Profile
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item text-white"
                      onClick={handleLogoutWithFilterClear}
                      href="#">
                      <i className="bi bi-person-walking me-2"></i>
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      {/* </div> */}
    </div>
  );
};

export default Navbar;
