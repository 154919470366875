import { useNavigate, useLocation } from "react-router-dom";

/**
 * A higher-order component that injects navigation and location props into the wrapped component.
 *
 * @param {React.ComponentType} Component - The component to wrap.
 * @returns {React.FC} A new functional component that renders the wrapped component with the `navigate` and `location` props injected.
 *
 * @example
 * const MyComponent = (props) => {
 *   const { navigate, location } = props;
 *   // Use navigate and location as needed
 * };
 *
 * export default withRouting(MyComponent);
 */
const withRouting = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <Component {...props} navigate={navigate} location={location} />;
  };
};

export { withRouting };
