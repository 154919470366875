export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_PLATFORM = "SET_SELECTED_PLATFORM";
export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
export const SET_SELECTED_STATUS = "SET_SELECTED_STATUS";
export const SET_SELECTED_PERFORMANCE = "SET_SELECTED_PERFORMANCE";
export const SET_HEATMAP_ON = "SET_HEATMAP_ON";
export const SET_SIZE_FACTOR = "SET_SIZE_FACTOR";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const SET_ID = "SET_ID";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_SUBPLATFORM_DATA = 'SET_SUBPLATFORM_DATA';
export const SET_USER = "SET_USER";

export const setStartDate = (date) => ({
  type: SET_START_DATE,
  payload: date,
});

export const setEndDate = (date) => ({
  type: SET_END_DATE,
  payload: date,
});

export const setSelectedCategory = (category, fromCheckbox = false) => {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: {category, fromCheckbox},
  };
};

export const setSelectedPlatform = (platform, fromCheckbox = false) => ({
  type: SET_SELECTED_PLATFORM,
  payload: {platform, fromCheckbox},
});

export const setSelectedType = (type, fromCheckbox = false) => ({
  type: SET_SELECTED_TYPE,
  payload: {type, fromCheckbox},
});

export const setSelectedStatus = (status, fromCheckbox = false) => ({
  type: SET_SELECTED_STATUS,
  payload: {status, fromCheckbox},
});

export const setSelectedPerformance = (performance_value, fromCheckbox = false) => ({
  type: SET_SELECTED_PERFORMANCE,
  payload: {performance_value, fromCheckbox},
});

export const setHeatmapOn = (heatmapOn) => ({
  type: SET_HEATMAP_ON,
  payload: heatmapOn,
});

export const setSizeFactor = (sizeFactor) => ({
  type: SET_SIZE_FACTOR,
  payload: sizeFactor,
});

export const setViewType = (viewType) => ({
  type: SET_VIEW_TYPE,
  payload: viewType,
});

export const setId = (id) => ({
  type: SET_ID,
  payload: id,
});

export const setSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  payload: text,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setSubPlatformData = (data) => ({
  type: SET_SUBPLATFORM_DATA,
  payload: data,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});