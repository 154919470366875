import React from 'react';

const FileChannel = ({ formData, handleChange }) => {
  return (
    <div className="mb-3 px-2">
      <div className="label mb-2">
        <h6>CHANNEL</h6>
      </div>
      <div className="d-flex align-items-center">
        <div className="col-2">
          <label htmlFor="channel" className="form-label text-white">
            Channel:
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-images"></i>
            </span>
            <select
              className="input-group form-select file-status text-white"
              id="channel"
              name="channel"
              required={true}
              value={formData.channel}
              onChange={handleChange}
            >
              <option value="" disabled>
                Please select a channel
              </option>
              <option value="print">Print</option>
              <option value="video_television">Video/Television</option>
              <option value="audio_radio">Audio/Radio</option>
              <option value="out_of_home_billboards">Out of Home/Billboards</option>
              <option value="others">Others</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileChannel;
