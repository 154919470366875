import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FileNamePath = ({ formData, handleChange, handleFileChange, handleDateChange }) => {
  return (
    <div className="mb-3 px-2">
      <div className="label mb-3">
        <h6>FILE NAME/PATH</h6>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="common_name" className="form-label text-white">
            Common Name: (optional)
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi bi-image"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs"
              id="common_name"
              name="common_name"
              value={formData.common_name}
              onChange={handleChange}
              placeholder="Enter a common name"
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="description" className="form-label text-white">
            Description: (optional)
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-textarea-t"></i>
            </span>
            <textarea
              className="form-control asset-inputs"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="4"
              placeholder="Enter a brief description..."
            ></textarea>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="filepath" className="form-label text-white">
            File Path: (optional)
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi bi-image"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs"
              id="filepath"
              name="filepath"
              value={formData.filepath}
              onChange={handleChange}
              placeholder="Enter the file path"
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="thumbnail" className="form-label text-white">
            File Thumbnail: (optional)
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <input
              type="file"
              className="form-control asset-inputs"
              id="thumbnail"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          <small className="form-text text-light">
            File upload limit is 30 MB.
          </small>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="creation_date" className="form-label text-white">
            Creation Date:
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <DatePicker
              className="form-control asset-inputs"
              selected={formData.creation_date ? new Date(formData.creation_date) : null}
              onChange={(date) => handleDateChange(date, 'creation_date')}
              placeholderText="Select Date"
              dateFormat="MMM dd, yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="start_date" className="form-label text-white">
            Start Date:
          </label>
        </div>
        <div className="col-4">
          <DatePicker
            className="form-control asset-inputs"
            selected={formData.asset_start_date ? new Date(formData.asset_start_date) : null}
            onChange={(date) => handleDateChange(date, 'asset_start_date')}
            placeholderText="Start Date"
            dateFormat="MMM dd, yyyy"
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            maxDate={formData.asset_end_date ? new Date(formData.asset_end_date) : null}
          />
        </div>
        <div className="col-2">
          <label htmlFor="end_date" className="form-label text-white">
            End Date:
          </label>
        </div>
        <div className="col-4">
          <DatePicker
            className="form-control asset-inputs"
            selected={formData.asset_end_date ? new Date(formData.asset_end_date) : null}
            onChange={(date) => handleDateChange(date, 'asset_end_date')}
            placeholderText="End Date"
            dateFormat="MMM dd, yyyy"
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            minDate={formData.asset_start_date ? new Date(formData.asset_start_date) : null}
          />
        </div>
      </div>
    </div>
  );
};

export default FileNamePath;
