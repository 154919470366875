import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Provider } from "react-redux";
import store from "./redux/store";

import AuthWrapper from "./AuthWrapper";
import ErrorBoundaries from "./common/components/generic/errorBoundaries/ErrorBoundaries";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundaries>
          <AuthWrapper/>
        </ErrorBoundaries>
      </Router>
    </Provider>
  );
}

export default App;
