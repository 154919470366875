import React from "react";
import localisable from "../../../constants/localisable";
import "./styles/DefaultErrorBoundary.css"; // Ensure to include the CSS file

const DefaultErrorBoundary = (props) => {
  const handleGoBack = () => {
    return props.navigate("/"); // Go back to the home page
  };

  return (
    <div className="error-boundary">
      <div className="error-container">
        <h1>{localisable.somethingWentWrong}</h1>
        <p>{localisable.errorPageMessage}</p>
        <button
          className="refresh-button"
          onClick={() => window.location.reload()}
        >
          {localisable.refreshPage}
        </button>
        <button className="refresh-button goback-btn" onClick={handleGoBack}>
          {localisable.returnHomePage}
        </button>
      </div>
    </div>
  );
};

export default DefaultErrorBoundary;
