import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../../config.json";
import Spinner from "../../../../components/spinner";
import "../../../../Styles/style.css";

const DetailsComments = ({ user, assetDetail, setActiveTab }) => {
  const [comment, setComment] = useState("");
  const [placeholderText, setPlaceholderText] = useState("Write a comment...");
  const [hasError, setHasError] = useState(false);
  const [fetchedComments, setFetchedComments] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current_page: 1,
    total_items: 0,
    total_pages: 1,
  });
  const [isloading, setisloading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [sendFollowers, setSendFollowers] = useState(false); // New state for checkbox
  const containerRef = useRef(null);

  const fetchComments = (page) => {
    const authToken = localStorage.getItem("token");
    const headers = {
      Authorization: authToken,
      "Content-Type": "application/json",
    };
    const body = {
      asset_id: assetDetail.id,
      page: page,
      per_page: 5,
    };
    setIsLoadingMore(true);
    axios
      .post(`${config.serviceUrl}/assets/get-asset-comments`, body, { headers })
      .then((response) => {
        setFetchedComments((prevComments) => [
          ...prevComments,
          ...response.data.comments,
        ]);
        setPaginationData({
          current_page: response.data.current_page,
          total_items: response.data.total_items,
          total_pages: response.data.total_pages,
        });
        setIsLoadingMore(false);
      })
      .catch((error) => {
        // console.error("Error fetching comments:", error);
        setIsLoadingMore(false);
      });
  };

  useEffect(() => {
    fetchComments(1);
    // eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    if (
      containerRef.current.scrollTop + containerRef.current.clientHeight ===
        containerRef.current.scrollHeight &&
      !isLoadingMore
    ) {
      if (paginationData.current_page < paginationData.total_pages) {
        fetchComments(paginationData.current_page + 1);
      }
    }
  };

  const addComment = (e) => {
    if (comment.trim() === "") {
      setPlaceholderText("Please enter a comment");
      setHasError(true);
    } else {
      setHasError(false);
      setisloading(true);
      const authToken = localStorage.getItem("token");
      const headers = {
        Authorization: authToken,
        "Content-Type": "application/json",
      };
      const body = {
        comment: comment,
        asset_id: assetDetail.id,
        send_followers: sendFollowers, 
        asset_url: window.location.href,
      };
      axios
        .post(`${config.serviceUrl}/assets/add-asset-comment`, body, { headers })
        .then((response) => {})
        .catch((error) => {
          // console.error("Error adding comment:", error);
        })
        .finally(() => {
          setComment("");
          setFetchedComments([]);
          setSendFollowers(false);
          fetchComments(1);
          setActiveTab("Comments");
          setisloading(false);
        });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <>
      <h3>Comments</h3>
      <hr />
      <div className="profile-content">
        <div className="card-body">
          <div className="d-flex">
            <i className="bi bi-chat-dots fs-3 me-2"></i>
            <input
              type="text"
              className={`form-control text-white rounded-4 bg-transparent me-1 ${
                hasError ? "error" : ""
              }`}
              placeholder={placeholderText}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                if (e.target.value.trim() !== "") {
                  setHasError(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  addComment();
                }
              }}
            />
            <button
              className="btn btn-primary rounded-start-4 rounded-end-4"
              onClick={addComment}
            >
              <i className="bi bi-arrow-right-circle-fill"></i>
            </button>
          </div>
          <div className="form-check mt-2 d-flex justify-content-end me-2 fs-6">
            <input
              className="form-check-input me-1"
              type="checkbox"
              id="sendFollowersCheckbox"
              checked={sendFollowers}
              onChange={(e) => setSendFollowers(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="sendFollowersCheckbox">
              Send mail to Followers
            </label>
          </div>
          <hr />
          {isloading ? (
            <Spinner />
          ) : (
            <div
              ref={containerRef}
              className="container"
              style={{ maxHeight: "300px", overflowY: "scroll" }}
              onScroll={handleScroll}
            >
              {fetchedComments.map((comment) => (
                <div key={comment.id} className="comment-container">
                  <div className="comment-header d-flex align-items-center">
                    <span className="d-flex align-items-center">
                      <i className="bi bi-person-circle fs-5 me-2"></i>
                      <b className="me-2">{comment.user_name}</b>
                    </span>
                    <span className="ms-auto">
                      {formatDate(comment.created_time)}
                    </span>
                  </div>
                  <div className="comment-body">
                    <p>{comment.comment}</p>
                  </div>
                </div>
              ))}
              {isLoadingMore && <Spinner />}
            </div>
          )}
          {fetchedComments.length === 0 && (
            <div className="d-flex align-items-center">
              <i className="bi bi-cloud-slash-fill me-1"></i>
              No comments or notes for this asset
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailsComments;