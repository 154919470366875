import config from '../config.json';

const ENDPOINTS = {
  ASSET:{
    getAssets: `${config.serviceUrl}/assets/get-assets`,
    getCount: `${config.serviceUrl}/assets/get-count`,
  },
  PERFORMANCE_METRIC: {
    getConfig: `${config.serviceUrl}/performance-metric/get-performance-metric-config`,
    addConfig: `${config.serviceUrl}/performance-metric/upsert-performance-metric-config`,
    getFields: `${config.serviceUrl}/performance-metric/get-performance-metric-fields`,
  },
  AUTH: {
    getUser: `${config.serviceUrl}/auth/get-user`,
    login: `${config.serviceUrl}/auth/login`
  },
  ONBOARDING:{
    add_user: `${config.serviceUrl}/onboarding/add-user`,
    update_user: `${config.serviceUrl}/onboarding/update-user`,
    update_user_password: `${config.serviceUrl}/onboarding/update-user-password`,
    update_password:`${config.serviceUrl}/onboarding/update-password`
  },
  JOURNEY_MAP: {
    getJourneyMap: `${config.serviceUrl}/journey_maps/get-all-journey-map`,
    add: `${config.serviceUrl}/journey_maps/add-journey-map`,
    delete: (id) => `${config.serviceUrl}/journey_maps/delete-journey-map/${id}`
  }
};

export default ENDPOINTS;