import React from 'react';

const FileOwnership = ({ formData, handleChange }) => {
  return (
    <div className="mb-3 px-2">
      <div className="label mb-2">
        <h6>OWNERSHIP</h6>
      </div>
      <div className="d-flex align-items-center">
        <div className="col-2">
          <label htmlFor="file_owner" className="form-label text-white">
            File Owner:
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-person"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="file_owner"
              name="file_owner"
              value={formData.file_owner}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileOwnership;
