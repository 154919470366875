import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import whiteLabel from "../assets/whitelabelConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/style.css";

const NavigationBar = () => {
  return (
    <Navbar bg="dark" expand="lg" fixed="top" variant="dark">
      <Navbar.Brand as={Link} to="/">
        <img
          src={whiteLabel.MktgAILogo}
          alt="mktg.ai"
          width="120"
          height="50"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-2 ml-auto" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mx-4">
        <Nav>
          {/* <Nav.Link as={Link} to="/" className="text-white">
            mktg.ai
          </Nav.Link>
          <Nav.Link as={Link} to="/about" className="text-white">
            About the Company
          </Nav.Link>
          <Nav.Link as={Link} to="/api-integrations" className="text-white">
            API Integrations
          </Nav.Link> */}
          <Nav.Link as={Link} to="https://www.mktg.ai/general-9" className="text-white">
            Policy
          </Nav.Link>
          <Nav.Link as={Link} to="/client-login" className="text-white">
            Client Login
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
