import React, {useState, useEffect, useCallback} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setSubPlatformData} from "../../../redux/actions";
import axios from "axios";
import config from "../../../config.json";
import Spinner from "../../../components/spinner";
import {formatKeys} from "../utils/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";

const IntegrationMap = (props) => {
  const [hideGrayedOut, setHideGrayedOut] = useState(false);
  const [shadeFilter, setShadeFilter] = useState("1");
  const [max_count, setMaxCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getIntegrationMap = useCallback(() => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      limit_per_asset_type: 25,
      performance_value: parseInt(shadeFilter),
    };
    axios
      .post(
        `${config.serviceUrl}/integration_maps/get-integration-map`,
        requestBody,
        configuration
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // console.error("Error fetching journey maps:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [shadeFilter]);

  useEffect(() => {
    getIntegrationMap();
  }, [getIntegrationMap]);

  const toggleHideGrayedOut = () => {
    setHideGrayedOut(!hideGrayedOut);
  };

  const handleShadeFilterChange = (event) => {
    setShadeFilter(event.target.value);
  };

  const handleCountFilterChange = (event) => {
    setMaxCount(event.target.value);
  };

  const renderColumns = (category, isLastColumn) => {
    return Object.entries(category).map(
      ([key, values], categoryIndex, categoriesArray) => (
        <div
          className={`col p-0 vh-100 ${
            categoryIndex === categoriesArray.length - 1 && !isLastColumn
              ? "border-end border-secondary"
              : ""
          }`}
          key={key}>
          <div
            className="d-flex align-items-center justify-content-center bg-success text-white py-2 position-relative"
            style={{minHeight: "60px"}}>
            <div className="text-center fw-bold" style={{fontSize: "10px"}}>
              {formatKeys(key)}
            </div>
          </div>

          {values.map((value, index) => {
            // Apply hide grayed out logic
            const isNonDigital = key === "non_digital";
            const isGray = isNonDigital
              ? value.count === 0
              : !value.has_configured_credentials;
            // Apply hide grayed out logic and shade filter logic
            if (
              (hideGrayedOut && isGray) ||
              (parseInt(shadeFilter) !== value.performance_value && !isGray)
            ) {
              return null;
            }

            // Determine the box color based on performance_value
            let boxColor = "#f8f9fa"; // Default light background
            let textColor = "#ffffff"; // Default text color is white
            let displayCount = true; // Flag to show or hide count
            const displayedCount = Math.min(value.count, max_count);

            if (isGray) {
              boxColor = "#6c757d"; // Gray for grayed-out items
              displayCount = false; // Do not display count
            } else {
              switch (value.performance_value) {
                case 0:
                  boxColor = "#000000"; // Black for Unknown
                  break;
                case 1:
                  boxColor = "#00a318"; // Dark green for Excellent
                  break;
                case 2:
                  boxColor = "#b2ff6b"; // Green for Good
                  textColor = "#000000";
                  break;
                case 3:
                  boxColor = "#FFFF00"; // Yellow for Average
                  textColor = "#000000";
                  break;
                case 4:
                  boxColor = "#ff6344"; // Red for Low
                  break;
                case 5:
                  boxColor = "#cf0000"; // Dark red for Very Low
                  break;
                default:
                  boxColor = "transparent"; // Transparent background
              }
            }

            const handleClick = () => {
              if (value.count > 0) {
                dispatch(
                  setSubPlatformData({
                    name: value.name,
                    category: value.category,
                    performance_value: value.performance_value,
                    displayedCount,
                  })
                );
                navigate(`/integrationmap/assetview/${value.name}`);
              }
            };

            return (
              <button
                onClick={handleClick}
                className={`box ${isGray ? "grayed-out" : ""}`}
                disabled={value.count < 1}
                key={index}
                style={{
                  backgroundColor: boxColor,
                  color: textColor,
                  textDecoration: "None",
                }}>
                {formatKeys(value.name)} {displayCount && `(${displayedCount})`}
              </button>
            );
          })}
        </div>
      )
    );
  };

  const renderCategoriesInOrder = () => {
    const categoryOrder = ["non_digital", "digital", "data_producing"];

    // Calculate the total number of items in each category
    const categoryCounts = categoryOrder.map((key) =>
      data && data[key] ? Object.keys(data[key]).length : 0
    );
    const totalItems = categoryCounts.reduce((sum, count) => sum + count, 0);

    // Calculate initial sizes and distribute remaining units
    let remainingUnits = 12;
    const sizes = categoryCounts.map((count, _, {length}) => {
      const size = totalItems
        ? Math.floor((count / totalItems) * 12)
        : Math.floor(12 / length);
      remainingUnits -= size;
      return size;
    });

    // Distribute remaining units if totalItems > 0
    if (totalItems > 0) {
      categoryCounts
        .map((count, i) => [(count / totalItems) * 12 - sizes[i], i])
        .sort((a, b) => b[0] - a[0])
        .slice(0, remainingUnits)
        .forEach(([, i]) => sizes[i]++);
    }

    return categoryOrder.map((key, index) => {
      const colSize = sizes[index];
      const isLastColumn = index === categoryOrder.length - 1;
      return (
        <div className={`col-${colSize} `} key={key}>
          <h5 className="d-flex justify-content-center align-items-center text-light">
            {formatKeys(key)}
          </h5>
          <div className="row">
            {data && data[key] ? renderColumns(data[key], isLastColumn) : null}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={
          "m-0 p-0 inner-nav position-fixed fixed-top row justify-content-end" +
          (props.isNavbarVisible ? " nav-up" : " nav-down")
        }
        style={{zIndex: "10"}}>
        {props.isSideBar && <div className="col-3 col-md-3 col-lg-2"></div>}
        <div
          className={`${
            props.isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
          } transition-width p-0`}>
          {/* Navigation bar */}
          <nav className="navbar navbar-expand-lg navbar-dark bg-black border border-secondary">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav w-100 d-flex py-1">
                  <li className="nav-item d-flex align-items-center text-white mx-2">
                    <label>Performance:</label>
                    <select
                      className="form-select bg-black text-white ms-2"
                      value={shadeFilter}
                      onChange={handleShadeFilterChange}
                      role="button">
                      <option value="1">Excellent</option>
                      <option value="2">Good</option>
                      <option value="3">Average</option>
                      <option value="4">Low</option>
                      <option value="5">Very Low</option>
                      <option value="0">Unknown</option>
                    </select>
                  </li>
                  <li className="nav-item d-flex align-items-center text-white mx-2">
                    <label className="text-nowrap">Max Count:</label>
                    <select
                      className="form-select bg-black text-white ms-2"
                      value={max_count}
                      onChange={handleCountFilterChange}
                      role="button">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </select>
                  </li>
                  <li className="nav-item text-white ms-auto">
                    <div className="d-flex align-items-center text-white rounded-2 border border-secondary ps-2 py-1">
                      <label className="me-2">Available Platforms</label>
                      <div className="form-check form-switch mt-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{cursor: "pointer"}}
                          checked={!hideGrayedOut}
                          onChange={toggleHideGrayedOut}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="position-relative text-white pb-4" style={{top: "120px"}}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <div className="row mt-4 mx-1">{renderCategoriesInOrder()}</div>
        )}
      </div>
    </div>
  );
};

export default IntegrationMap;
