import React, { useState, useEffect } from "react";
import "../../../../Styles/style.css";

const Listview = ({ props,dateRange, selectedCategory}) => {
  const { data: initialData } = props;
  const [data, setData] = useState(initialData);
  // Sample data
  // const [data, setData] = useState([
  //   { id: 1, asset: "Asset 1", fileName: "file1.jpg", kindChannel: "Image", typeSize: "JPEG, 100KB" },
  //   { id: 2, asset: "Asset 2", fileName: "file2.mp4", kindChannel: "Video", typeSize: "MP4, 10MB" },
  //   { id: 3, asset: "Asset 3", fileName: "file3.pdf", kindChannel: "Document", typeSize: "PDF, 500KB" },
  //   { id: 4, asset: "Asset 4", fileName: "file4.mp3", kindChannel: "Audio", typeSize: "MP3, 5MB" },
  //   { id: 5, asset: "Asset 5", fileName: "file5.txt", kindChannel: "Text", typeSize: "TXT, 1KB" },
  //   { id: 6, asset: "Asset 1", fileName: "file1.jpg", kindChannel: "Image", typeSize: "JPEG, 100KB" },
  //   { id: 7, asset: "Asset 2", fileName: "file2.mp4", kindChannel: "Video", typeSize: "MP4, 10MB" },
  //   { id: 8, asset: "Asset 3", fileName: "file3.pdf", kindChannel: "Document", typeSize: "PDF, 500KB" },
  //   { id: 9, asset: "Asset 4", fileName: "file4.mp3", kindChannel: "Audio", typeSize: "MP3, 5MB" },
  //   { id: 10, asset: "Asset 5", fileName: "file5.txt", kindChannel: "Text", typeSize: "TXT, 1KB" }
  // ]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Sorting
  const [sortType, setSortType] = useState(null);

  const sortData = (type) => {
    const sortedData = [...data];
    if (type === "asc") {
      sortedData.sort((a, b) => (a.asset > b.asset ? 1 : -1));
    } else {
      sortedData.sort((a, b) => (a.asset < b.asset ? 1 : -1));
    }
    setData(sortedData);
    setSortType(type);
  };

  // Search
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const searchTerm = event.target.value.trim().toLowerCase();
    setSearchTerm(searchTerm);
    const filteredData = initialData.filter((item) => {
      // Check if any field in the item exactly matches the search term or contains it as a substring
      return Object.values(item).some((val) => {
        // Skip null or undefined values
        if (val === null || val === undefined) {
          return false;
        }
        // Converting value to lowercase and split it into words
        const words = val.toString().toLowerCase().split(' ');
        // Checking if any word in the field value matches the search term at the beginning
        return words.some(word => word.startsWith(searchTerm));
      });
    });
    // Update the data state with filteredData or initialData if search term is empty
    setData(searchTerm ? filteredData : initialData);
  };
  
  

  useEffect(() => {
    // Filter data based on selectedCategory and dateRange
    const filteredData = initialData.filter((item) => {
      // Convert both category and selectedCategory to lowercase for case-insensitive comparison
      const categoryLowerCase = item.category.toLowerCase();
      const selectedCategoryLowerCase = selectedCategory.toLowerCase();

      // Filter based on date range
      const createdDate = new Date(item.created_time);
      const startDate = new Date(dateRange.start_date);
      const endDate = new Date(dateRange.end_date);
      const dateInRange = createdDate >= startDate && createdDate <= endDate;

      // Filter based on selected category (case-insensitive comparison)
      const categoryMatch =
        categoryLowerCase === selectedCategoryLowerCase ||
        selectedCategoryLowerCase === "show all";

      return dateInRange && categoryMatch;
    });

    setData(filteredData);
  }, [initialData, dateRange, selectedCategory]);

  const searchResults = !searchTerm
  ? currentItems
  : currentItems.filter((item) =>
      Object.values(item).some((val) => {
        if (val === null || val === undefined) {
          return false; // Skip null or undefined values
        }
        return val.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    );


    return (
      <div className="container text-white rounded-bottom pt-2 list-view mt-4 mt-md-4 mt-sm-4 mt-xl-2">
        <div className="row mb-2">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="show me-1">Show:</span>
              <select
                id="itemsPerPage"
                className="form-select  me-1 bg-white opacity-25"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
              <span className="entries">entries</span>
            </div>
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="search me-1">Search:</span>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={handleSearch}
                className="form-control bg-white opacity-25"
              />
            </div>
          </div>
        </div>
        <table className="table table-striped table-hover table-bordered border-secondary border-2 custom-table">
          <thead className="th-listview">
            <tr>
              <th onClick={() => sortData(sortType === "asc" ? "desc" : "asc")}>
                ID{" "}
                {sortType && sortType === "asc" ? (
                  <i className="bi bi-arrow-up"></i>
                ) : (
                  <i className="bi bi-arrow-down"></i>
                )}
              </th>
              <th>Asset</th>
              <th>File Name</th>
              <th>Kind</th>
              <th>Channel</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td className="align-center img-cell">
                  <img src={item.image_url} alt={item.title} className="img-fluid rounded"/>
                </td>
                <td>{item.fileName}</td>
                <td>
                  {item.file_extension}
                </td>
                <td>
                {item.sub_media_platform}
                </td>
                <td>{item.file_size}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row text-white">
          <div className="col">
            <p>
              Showing {indexOfFirstItem + 1} to{" "}
              {indexOfLastItem > data.length ? data.length : indexOfLastItem} of{" "}
              {data.length} entries
            </p>
          </div>
          <div className="col-auto">
            <ul className="pagination paginate">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
              {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map(
                (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                )
              )}
              <li
                className={`page-item ${
                  currentPage === Math.ceil(data.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
};

export default Listview;
