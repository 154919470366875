import React from "react";
import PropTypes from "prop-types";
import DefaultErrorBoundary from "./DefaultErrorBoundary";
import { withRouting } from "../../../hoc";


export class ErrorBoundaries extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      hasError: false,
      error: null,
      info: null,
    }
    this.state = {
      ...this.initialState,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true, error: error };
  }

  componentDidUpdate(prevProps) {
    // Check if the path has changed and reset error boundary state
    if (this.props?.location?.pathname !== prevProps?.location?.pathname) {
      this.setState(this.initialState);
    }
  }

  componentDidCatch(error, info) {
    // You can log the error to an error reporting service
    this.setState({ hasError: true, error, info });
    // Todo: Build a logger service that sends mail with detailed traceback
    // logErrorToMyService(error, info.componentStack);
    // console.error("Error caught by Error Boundary: ", error, info);
  }

  render() {
    const { children, fallback } = this.props;
    if (this.state.hasError) {
      if (fallback) {
        // if fallback UI component is passed as a props then render it
        return fallback;
      }
      // You can render any custom fallback UI
      return <DefaultErrorBoundary {...this.props} />;
    }
    return children;
  }
}

ErrorBoundaries.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default withRouting(ErrorBoundaries);
