const deepCopy = object => JSON.parse(JSON.stringify(object));

const isValidObject = obj => (typeof obj === 'object' && obj !== null);

const isObjWithKeys = obj => (isValidObject(obj) ? Object.keys(obj).length > 0 : false);

const capitalize = (str = '', global = false) => (
  global ? str.replace(/\b\w/g, match => match.toUpperCase())
      : str.charAt(0).toUpperCase() + str.slice(1)
);

const getFieldName = (name) => {
  const delimiter = '.';
  return name.includes(delimiter) ? name.split(delimiter) : name;
};

const setValue = (obj, property, value) => {
    const localObj = obj;
    const fieldNames = getFieldName(property);
    if (Array.isArray(fieldNames)) {
        const lastField = fieldNames.pop();
        let traversedObject = {};
        fieldNames.forEach((fieldName) => {
            traversedObject = traversedObject && typeof traversedObject[fieldName] !== 'undefined'
                ? traversedObject[fieldName]
                : localObj[fieldName];
            if (!traversedObject) {
                traversedObject = { [fieldName]: {} };
            }
        });
        // Traversed n-1 fields
        if ((Array.isArray(traversedObject[lastField]) && isValidObject(traversedObject[lastField]))) {
            const curTraversedArray = traversedObject[lastField];
            curTraversedArray.forEach((element, index) => {
                if (isValidObject(element)) {
                    // Iterate through the value property to set
                    for (const prop in value) {
                        if (element.hasOwnProperty(prop)) {
                            // If property exists set value
                            // eslint-disable-next-line no-param-reassign
                            element[prop] = value[prop];
                        }
                    }
                } else {
                    traversedObject[lastField][index] = value[index];
                }
            });
        } else {
            traversedObject[lastField] = value;
        }
    } else {
        localObj[property] = value;
    }
};


const getValue = (obj, name) => {
  if (!obj || !name) {
      throw new TypeError('Invalid Parameter Values');
  }

  const objToTraverse = deepCopy(obj); // To avoid reference issue
  let traversedObject;
  const fieldNames = getFieldName(name);
  if (Array.isArray(fieldNames)) {
      traversedObject = objToTraverse[fieldNames[0]] || {};
      for (let index = 1; index < fieldNames.length; index += 1) {
          traversedObject = traversedObject[fieldNames[index]];
          if (!traversedObject) {
              break; // Can't traverse deeper
          }
      }
  } else {
      traversedObject = obj && obj[fieldNames];
  }
  return traversedObject;
};

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const flattenObject = (obj, replacementChar = '.') => {
    if (!isObjWithKeys(obj)) return obj
    const result = {}
    for(const i in obj){
        if(!obj.hasOwnProperty(i)) continue;
        if(isValidObject(obj[i])){
            const flatObject = flattenObject(obj[i], replacementChar);
            for(const x in flatObject){
                if(!flatObject.hasOwnProperty(x)) continue;

                result[`${i}${replacementChar}${x}`] = flatObject[x];
            }
        } else {
            result[i] = obj[i];
        }
    }
    return result;
}

const isNumber = (inputValue) => inputValue === '' || /^[0-9]*\.?[0-9]*$/.test(inputValue);

/**
 * Converts a string input into a valid number or retains specific string patterns. Use it with parseFloat if you don't want ending with decimal `eg: 0.`
 * 
 * This function attempts to convert a given string into a floating-point number 
 * while handling various edge cases like incomplete numeric inputs (e.g., "1."), 
 * strings with multiple decimal points, or non-numeric characters.
 * 
 * Rules:
 * 1. If the input is an empty string, a single "-" (negative sign), or ".", 
 *    the input is returned as is.
 * 2. If the input contains only valid numeric characters, it is parsed into a float.
 * 3. If the input has multiple decimal points, the function returns an empty string
 *    to indicate invalid input.
 * 4. If the input ends with a decimal point (e.g., "1."), it retains the string format.
 * 5. Trailing zeros after the decimal (e.g., "1.00") are preserved as part of the string.
 * 6. If the parsed number is `0` but the string is not exactly "0", the original 
 *    string is returned to handle cases like "0." or "-0.".
 * 
 * @param {string} str - The string to be converted to a number.
 * 
 * @returns {number|string} - The parsed float number if the string is a valid number. 
 *                            Returns the original string in cases where input is incomplete 
 *                            but potentially valid (e.g., "1."), or an empty string for invalid inputs.
 */
const stringToNumber = (str) => {
    return str.match(/^-?\d*\.?\d*/)?.[0]
}

export { camelToSnakeCase, capitalize, deepCopy, flattenObject, getValue, getFieldName, isNumber, isValidObject, isObjWithKeys, setValue, stringToNumber };