import React from "react";
import Navbar from "../components/navbar";
import whiteLabel from "../assets/whitelabelConfig";

const ploicy = () => {
  return (
    <>
      <div className="policy-background">
        <Navbar />
        <div className="container"></div>
        <div className="mt-5 pt-5 d-flex justify-content-center text-white">
          <h1 className="mb-4">Privacy Policy</h1>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 d-flex justify-content-center pb-5">
              <img
                src={whiteLabel.MktgAILogo}
                style={{height: "200px"}}
                alt="mktg_logo"></img>
            </div>
            <div className="col-6 bg-black text-white p-4">
              <p>
                Privacy Policy This Privacy Policy governs the online
                information collection practices of INTEG:Advisors, LLC (“we,”
                “us,” or “our”), which owns and operates the website at mktg.ai
                (the “Site”). Specifically, it outlines the types of information
                that we gather about you while you are using the Site and the
                ways in which we use this information. This Privacy Policy does
                not apply to any information you may provide to us or that we
                may collect offline and/or through other means (for example, at
                a live event, via telephone, or through the mail). Please read
                this Privacy Policy carefully. By visiting and using the Site,
                you agree that your use of our Site, and any dispute over
                privacy, is governed by this Privacy Policy. If you do not agree
                with the terms of this Privacy Policy, you must discontinue your
                use of the Site.
              </p>
              <p>
                We reserve the right to change our Privacy Policy at any time.
                We will post any changes to this Privacy Policy on the Site and
                update the Effective Date of the policy to reflect the date of
                the changes. You should periodically review this page to ensure
                that you understand the terms of the Privacy Policy currently in
                effect. By continuing to use the Site after we post any such
                changes, you accept the Privacy Policy as modified.
              </p>
            </div>
          </div>
        </div>
        <div className="container bg-black text-white p-4 my-5">
          <h4 className="my-3">
            {" "}
            HOW WE COLLECT AND USE INFORMATION For purposes of this Privacy
          </h4>
          <p>
            Policy, information that can be used to identify you, including
            without limitation your name, phone number, mailing address, and
            email address, is referred to as “Personal Information.” We may
            collect and store Personal Information or other information that you
            voluntarily supply to us online while using the Site. For example,
            we may collect and store your Personal Information or other
            information when you contact us using forms available through the
            Site, post information in publicly-accessible areas of the Site, or
            enter a contest available through the Site. Please keep in mind that
            whenever you voluntarily make your Personal Information available
            for viewing by third parties on the Site – for example on message
            boards, web logs, through email, or in chat areas – that information
            can be seen, collected and used by others besides us. You
            acknowledge and agree that we are not responsible for any
            third-party use of such information.
          </p>
          <p className="my-2">
            We also collect and store information that is generated
            automatically as you navigate online through the Site. For example,
            we may collect information about your computer’s connection to the
            Internet, which allows us, among other things, to improve the
            delivery of our web pages to you and to measure traffic on the Site.
            We also may use a standard feature found in browser software called
            a “cookie” to enhance your experience with the Site. Cookies are
            small files that your web browser places on your hard drive for
            record-keeping purposes. By showing how and when visitors use the
            Site, cookies help us deliver advertisements, identify how many
            unique users visit us, and track user trends and patterns. They also
            prevent you from having to re-enter your preferences on certain
            areas of the Site where you may have entered preference information
            before. The Site also may use web beacons (single-pixel graphic
            files also known as “transparent GIFs”) to access cookies and to
            count users who visit the Site or open HTML-formatted email
            messages. We use the information we collect from and about you
            (including without limitation your Personal Information) while you
            are using the Site in a variety of ways, including using the
            information to customize features and advertising that appear on the
            Site. We also may provide any information in our possession
            (including without limitation your Personal Information) to third
            parties, such as service providers, contractors, and advertisers,
            for a variety of purposes. We reserve the right to use, and to
            disclose to third parties, all of the information collected from and
            about you while you are using the Site (including without limitation
            your Personal Information) in any way and for any purpose, including
            without limitation, to enable us or a third party to provide you
            with information about products and services.
          </p>
          <p className="my-2">
            We reserve the right to release information about our visitors when
            release is appropriate to comply with law or to protect the rights,
            property or safety of users of the Site or the public or when we
            otherwise believe, in our sole discretion, that release is
            appropriate. Please also note that as our business grows, we may buy
            or sell various assets. In the event that we sell some or all of our
            assets, or our Site is acquired by another company, information
            about our users may be among the transferred assets. In the event of
            such a transfer, we cannot guarantee that this Privacy Policy will
            continue to govern your information. ​
          </p>
          <h4 className="my-3">
            {" "}
            THIRD-PARTY COLLECTION AND USE OF YOUR INFORMATION
          </h4>
          Some of our third-party advertisers and ad servers that place and
          present advertising on the Site also may collect information from you
          via cookies, web beacons or similar technologies. These third-party
          advertisers and ad servers may use the information they collect to
          help present their advertisements, to help measure and research the
          advertisements’ effectiveness, or for other purposes. The use and
          collection of your information by these third-party advertisers and ad
          servers is governed by the relevant third-party’s privacy policy and
          is not covered by our Privacy Policy. If you have any concerns about a
          third party’s use of cookies or web beacons or use of your
          information, you should visit that party’s web site and review its
          privacy policy.
          <h4 className="my-3"> THIRD-PARTY LINKS </h4>
          The Site also includes links to other web sites and provides access to
          products and services offered by third parties, whose privacy policies
          we do not control. The appearance of any third-party links on the Site
          does not indicate any endorsement or sponsorship by us of the goods or
          services available on those third-party sites or the owners or
          operators of such sites. When you access another web site or purchase
          third-party products or services through the Site, use of any
          information you provide is governed by the privacy policy of the
          operator of the site you are visiting or the provider of such products
          or services. You should read the privacy policy governing any
          third-party site that you visit.
          <h4 className="my-3"> CHILDREN’S PRIVACY </h4>
          The Site is not directed to children under the age of thirteen and we
          do not knowingly collect personally identifiable information from
          children under the age of thirteen through the Site. If we become
          aware that we have received personally identifiable information from a
          user under the age of thirteen through the Site, we will delete such
          information from our records. If we change our practices in the
          future, we will obtain prior, verifiable parental consent before
          collecting any personally identifiable information from children under
          the age of thirteen through the Site.
          <h4 className="my-3"> INFORMATION RETENTION </h4>
          Unless you contact us to request the deletion of your Personal
          Information, we will retain such information for as long as it is
          necessary to fulfill a legitimate business purpose or as required by
          law.
          <h4 className="my-3"> SECURITY </h4>
          We employ certain physical, administrative, and technical measures in
          an attempt to protect your Personal Information. Although we do
          attempt to keep your Personal Information secure, we cannot guarantee
          the security of your Personal Information. Your communications with us
          are subject to all of the risks associated with the Internet,
          including without limitation unauthorized access and use.
          <h4 className="my-3"> QUESTIONS REGARDING PRIVACY </h4>
          If you have any questions about this Privacy Policy, our privacy
          practices, or your dealings with us, you can contact us at{" "}
          <a href="mailto:privacypolicy@integadvisors.com">
            privacypolicy@integadvisors.com
          </a>
        </div>
      </div>
    </>
  );
};

export default ploicy;
