import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import Spinner from "../../../../components/spinner";
import config from "../../../../config.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../Styles/style.css";

const DetailsShare = ({ user, assetDetail, setActiveTab }) => {
  const [users, setUsers] = useState([]); // State to store user data
  const [followers, setFollowers] = useState([]); // State to store followers data
  const [selectedUsers, setSelectedUsers] = useState([]); // State to store selected users
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    fetchFollowers();
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const fetchUserData = () => {
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      is_admin_console: false,
    };
    axios
      .post(`${config.serviceUrl}/onboarding/list-users`, body, configuration)
      .then((response) => {
        setUsers(response.data); // Set the fetched users to state
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchFollowers = () => {
    setisloading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      asset_id: assetDetail.id,
    };
    axios
      .post(`${config.serviceUrl}/assets/get-asset-followers`, body, configuration)
      .then((response) => {
        setFollowers(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        // console.log(error);
      }).finally(()=>{
        setisloading(false);
      });
  };

  const AddFollower = () => {
    setisloading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const selectedUserIds = selectedUsers.map(user => user.id); // Get the list of selected user IDs
    const body = {
      share_to: selectedUserIds,
      asset_id: assetDetail.id,
      asset_url: window.location.href,
    };
    axios
      .post(`${config.serviceUrl}/assets/share-asset`, body, configuration)
      .then((response) => {
        // console.log("Share successful", response.data);
        // Optionally, you can do something after the share is successful
      })
      .catch((error) => {
        // console.log("Error sharing", error);
      }).finally(()=>{
        fetchFollowers();
        setSelectedUsers([]);
      });
  };

  const RemoveFollower = (id) => {
    setisloading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      asset_id: assetDetail.id,
      follower_id: id,
    };
    axios
      .post(`${config.serviceUrl}/assets/remove-follower`, body, configuration)
      .then((response) => {
        setFollowers(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        // console.log(error);
      }).finally(()=>{
        fetchFollowers();
        setSelectedUsers([]);
      });
  };

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const searchResults = document.getElementById("searchResults");
    searchResults.innerHTML = "";

    const handleClickOutside = (event) => {
      if (!searchResults.contains(event.target)) {
        searchResults.style.display = "none";
        document.removeEventListener("click", handleClickOutside);
      }
    };

    if (keyword.trim() !== "") {
      const matchingUsers = users.filter((user) =>
        user.name.toLowerCase().includes(keyword)
      );

      if (matchingUsers.length > 0) {
        matchingUsers.forEach((user) => {
          // Check if the user is not already selected
          if (
            !selectedUsers.some(
              (selectedUser) => selectedUser.email === user.email
            )
          ) {
            const item = document.createElement("button");
            item.classList.add("dropdown-item");
            item.type = "button";
            item.textContent = user.name;
            item.addEventListener("click", () => {
              // Add selected user to the list of selected users
              setSelectedUsers((prevUsers) => [...prevUsers, user]);
              // Clear input field after selection
              event.target.value = "";
              // Hide the dropdown after selection
              searchResults.style.display = "none";
              // Remove the click outside event listener
              document.removeEventListener("click", handleClickOutside);
            });
            searchResults.appendChild(item);
          }
        });
      } else {
        const noResultItem = document.createElement("button");
        noResultItem.classList.add("dropdown-item", "disabled");
        noResultItem.type = "button";
        noResultItem.textContent = "No matching users found";
        searchResults.appendChild(noResultItem);
      }

      searchResults.style.display = "block";

      // Add event listener to handle clicks outside of the dropdown
      document.addEventListener("click", handleClickOutside);
    } else {
      searchResults.style.display = "none";
    }
  };

  return (
    <>
      <h3>Share</h3>
      <hr />
      <div className="profile-content">
        <div className="card-body">
          <div className="d-flex">
            <i className="bi bi-person-add fs-3 me-2"></i>
            <InputGroup>
              <FormControl
                placeholder="Search People"
                className="text-white rounded-4 bg-transparent me-1"
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          <div id="searchResults" className="dropdown-menu ms-4">
            {/* Dropdown items will be dynamically added here */}
          </div>
          <div className={`${selectedUsers.length > 0 ? "d-block" : "d-none"}`}>
            <div className="selected-users mt-3 fs-6">
              <p>Selected Users:</p>
              {selectedUsers.map((user) => (
                <span key={user.email} className="badge bg-secondary me-1">
                  {user.name}
                  <button
                    type="button"
                    className="btn-close btn-close-white ms-1"
                    onClick={() =>
                      setSelectedUsers((prevUsers) =>
                        prevUsers.filter((u) => u.email !== user.email)
                      )
                    }
                    aria-label="Remove"
                  ></button>
                </span>
              ))}
            </div>
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={AddFollower}>
                <i className="bi bi-share-fill me-1"></i>
                Share
              </Button>
            </div>
          </div>
          <hr />
          <div className="Owner mb-3">
          <h5 className="d-flex align-items-center">
          <i className="bi bi-person me-1"></i> Owner:</h5>
          <div className="justify-content-between align-items-center bg-transparent text-white fs-6">
          <i className="bi bi-person-circle ms-3 fs-6"></i> {assetDetail.asset_owner}
          </div>
        </div>
        <hr />
        <div className="Follower">
          <h5><i className="bi bi-people"></i> Followers:</h5>
          {isloading ? (
            <Spinner />
          ) : (
            <>
              {followers.length > 0 ? (
                <ol className="list-group list-group-flush me-1">
                  {followers.map((follower, index) => (
                    <li
                      key={follower.id}
                      className="list-group-item d-flex justify-content-between align-items-center bg-transparent text-white fs-6"
                      style={{ borderBottom: '1px solid #444' }}
                    >
                      <span>
                        <i className="bi bi-person-circle me-1"></i>
                        {follower.name}
                      </span>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        title="Remove Follower"
                        onClick={() => RemoveFollower(follower.id)}
                        style={{ padding: '0.25rem 0.5rem' }}
                      >
                        <i className="bi bi-person-x fs-6"></i>
                      </button>
                    </li>
                  ))}
                </ol>
              ) : (
                <p>No followers found.</p>
              )}
            </>
          )}
        </div>
        </div>
      </div>
    </>
  );
};

export default DetailsShare;
