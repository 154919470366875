import React from "react";
import { Form } from "react-bootstrap";
import PerformanceMetricInputField from "./PerformanceMetricInputField";
import { EMPTY_FUNC } from "../../../common/constants/constants";
import { camelToSnakeCase, capitalize, getValue } from "../../../common/helpers/utils/DataHelper";
import { getIcon } from "../../../common/utils";
import { labelFormatter } from "../../../common/helpers/utils/Formatter";
import { isTwoDecimalPlaces } from "./utils/utils";

const PerformanceForm = ({
  showcheckbox,
  handleChange = EMPTY_FUNC,
  metricOptions = [],
  formData = {},
  isEditMode,
  typeKey,
  platform,
  subPlatform,
  category,
  activeTabData = {},
  showSubmitButton,
  ...restProps
}) => {
  const performanceMetricFieldPath = [
    platform,
    activeTabData.subPlatform,
    activeTabData.category,
    typeKey,
  ].join(".");
  const performanceFieldValues = getValue(formData, performanceMetricFieldPath) || {};
  const isDisabled = performanceFieldValues.use_platform_metric || getValue(performanceFieldValues, "metric_field") === "not_applicable" ||
    (!isEditMode && !showSubmitButton);
  const isRequired = subPlatform === activeTabData.subPlatform && activeTabData.category === category;
  const commonInputFieldKey = `${platform}-${activeTabData.subPlatform}-${activeTabData.category}-${typeKey}`;

  return (
    <div className="mb-3 col-md-auto">
      <div className="d-flex align-items-center justify-content-center mb-2">
        <i
          className={`bi ${getIcon(typeKey)} me-2`}
          style={{ fontSize: "1.2rem" }}
        />
        <div className="tab-text">{capitalize(typeKey)}</div>
      </div>
      <div className="mb-3">
        <div className="row">
          {showcheckbox && (
            <Form.Group controlId={`${platform}-${subPlatform}-${category}-${typeKey}-use_platform_metric`}>
              <Form.Check
                className="mt-2 text-align-center"
                type="checkbox"
                name="use_platform_metric"
                label="Use default Platform Metric"
                checked={performanceFieldValues.use_platform_metric}
                onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
                disabled={!isEditMode && !showSubmitButton}
              />
            </Form.Group>
          )}
        </div>
        <div className="row">
          <Form.Group controlId={`${platform}-${subPlatform}-${category}-${typeKey}-metric_field`}>
            {metricOptions.length > 0 ? (
              <Form.Control
                key={`${commonInputFieldKey}-metric_field`}
                as="select"
                name="metric_field"
                value={performanceFieldValues.metric_field}
                onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
                required={
                  activeTabData.subPlatform === subPlatform &&
                  activeTabData.category === category &&
                  !performanceFieldValues.use_platform_metric
                }
                disabled={
                  (!isEditMode && !showSubmitButton) ||
                  performanceFieldValues.use_platform_metric
                }
              >
                <option value="">Select Metric Field</option>
                {metricOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {capitalize(labelFormatter(camelToSnakeCase(option)), true)}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                key={`${commonInputFieldKey}-metric_field`}
                type="text"
                name="metric_field"
                value={performanceFieldValues.metric_field}
                onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
                placeholder="No metric fields available"
                disabled={isDisabled}
              />
            )}
          </Form.Group>
        </div>
        <div className="row mt-3 pt-3">
          <PerformanceMetricInputField
            key={`${commonInputFieldKey}-excellent_core`}
            type="text"
            name="excellent_score"
            value={performanceFieldValues.excellent_score}
            onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
            placeholder="Enter excellent score"
            required={isRequired}
            disabled={isDisabled}
            controlId={`${commonInputFieldKey}-excellent_core`}
            labelClassName="text-dark"
            validate={isTwoDecimalPlaces}
            groupClassName="w-100"
            {...restProps}
          />
        </div>
        <div className="row mt-3 pt-3">
          <PerformanceMetricInputField
            key={`${commonInputFieldKey}-good_score`}
            type="text"
            name="good_score"
            value={performanceFieldValues.good_score}
            onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
            placeholder="Enter good score"
            required={isRequired}
            disabled={isDisabled}
            controlId={`${commonInputFieldKey}-good_score`}
            labelClassName="text-dark"
            validate={isTwoDecimalPlaces}
            groupClassName="w-100"
            {...restProps}
          />
        </div>
        <div className="row mt-3 pt-3">
          <PerformanceMetricInputField
            key={`${commonInputFieldKey}-average_score`}
            type="text"
            name="average_score"
            value={performanceFieldValues.average_score}
            onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
            placeholder="Enter average score"
            required={isRequired}
            disabled={isDisabled}
            controlId={`${commonInputFieldKey}-average_score`}
            labelClassName="text-dark"
            validate={isTwoDecimalPlaces}
            groupClassName="w-100"
            {...restProps}
          />
        </div>
        <div className="row mt-3 pt-3">
          <PerformanceMetricInputField
            key={`${commonInputFieldKey}-low_score`}
            type="text"
            name="low_score"
            value={performanceFieldValues.low_score}
            onChange={(e) => handleChange(e, platform, subPlatform, category, typeKey)}
            placeholder="Enter low score"
            required={isRequired}
            disabled={isDisabled}
            controlId={`${commonInputFieldKey}-low_score`}
            labelClassName="text-dark"
            validate={isTwoDecimalPlaces}
            groupClassName="w-100"
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceForm;
