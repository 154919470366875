import React from 'react';

const FileMeta = ({ formData, handleChange, formatSize }) => {
  return (
    <div className="mb-3 px-2">
      <div className="label mb-2">
        <h6>FILE META</h6>
      </div>

      <div className="d-flex justify-content-center mb-3 px-4">
        <div className="col-4 mx-2">
          <label htmlFor="asset_type" className="form-label text-white">
            Asset Type:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi-images"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="asset_type"
              name="asset_type"
              value={formData.asset_type}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="extension" className="form-label text-white">
            File Extension:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-file-earmark"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="extension"
              name="extension"
              value={formData.extension}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="color_range" className="form-label text-white">
            Color Range:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-palette"></i>
            </span>
            <select
              className="input-group form-select file-status text-white"
              id="color_range"
              name="color_range"
              value={formData.color_range}
              onChange={handleChange}
            >
              <option value="CMYK">CMYK</option>
              <option value="Hex">Hex</option>
              <option value="RGB">RGB</option>
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mb-3 px-4">
        <div className="col-4 mx-2">
          <label htmlFor="size" className="form-label text-white">
            Size:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-aspect-ratio"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="size"
              name="size"
              value={formatSize(formData.size)}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="width" className="form-label text-white">
            Width:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-aspect-ratio"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="width"
              name="width"
              value={formData.width}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="height" className="form-label text-white">
            Height:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-aspect-ratio"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs bg-transparent"
              id="height"
              name="height"
              value={formData.height}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mb-3 px-4">
        <div className="col-4 mx-2">
          <label htmlFor="encoding" className="form-label text-white">
            Encoding:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi-images"></i>
            </span>
            <select
              className="input-group form-select file-status text-white"
              id="encoding"
              name="encoding"
              value={formData.encoding}
              onChange={handleChange}
            >
              <option value="None">None</option>
              <option value="H.264">H.264, AAC</option>
              <option value="HD">HD (1-1-1)</option>
            </select>
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="audio" className="form-label text-white">
            Audio:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-file-earmark"></i>
            </span>
            <select
              className="input-group form-select file-status text-white"
              id="audio"
              name="audio"
              value={formData.audio}
              onChange={handleChange}
            >
              <option value="None">None</option>
              <option value="mono">Mono</option>
              <option value="stereo">Stereo</option>
            </select>
          </div>
        </div>
        
        <div className="col-4 mx-2">
          <label htmlFor="duration" className="form-label text-white">
            Duration:
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi-images"></i>
            </span>
            <input
              type="text"
              className="form-control asset-inputs"
              id="duration"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileMeta;
