import React from 'react';

const FileStatus = ({ formData, handleChange }) => {
  return (
    <div className="mb-3 px-2">
      <div className="label mb-3">
        <h6>FILE STATUS</h6>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="col-2">
          <label htmlFor="performance_value" className="form-label text-white">
            Performance:
          </label>
        </div>
        <div className="col-10">
          <div className="input-group">
            <span className="input-group-text bg-transparent text-white">
              <i className="bi bi-map"></i>
            </span>
            <select
              className="input-group form-select file-status text-white"
              id="performance_value"
              name="performance_value"
              value={formData.performance_value}
              onChange={handleChange}
            >
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Low</option>
              <option value="5">Very Low</option>
              <option value="0">Unknown</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileStatus;
