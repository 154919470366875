import React from 'react';

const footer = () => {
  return (
    <footer className="row bg-black text-white">
      <div className="row py-1" style={{fontSize:"12px", height:"24px"}}>
         {/* Privacy Policy and Data Protection */}
         <div className="col text-start">
            <a 
              className="d-inline text-white text-decoration-none" 
              href="https://www.mktg.ai/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <span className="mx-2">|</span>
            <a 
              className="d-inline text-white text-decoration-none" 
              href="https://www.mktg.ai/dataprotectiongdpr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Protection & GDPR
            </a>
          </div>

          {/* Copyright */}
          <div className="col text-center">
            <p className='mb-0'>&copy; 2024 mktg.ai Inc</p>
          </div>

          {/* Contact */}
          <div className="col text-end">
            <a 
              className="text-white text-decoration-none" 
              href="https://www.mktg.ai/blank-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
      </div>
  </footer>
  );
};
export default footer;
