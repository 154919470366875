import React, {useState} from "react";
import axios from "axios";
import Spinner from "../../components/spinner";
import whiteLabel from "../../assets/whitelabelConfig";
import { formatKeys } from "./utils/utils";
import ENDPOINTS from "../../common/endpoints";
// import { encryptPassword } from "../../common/utils";
import {Modal} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Profile = ({user}) => {
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setShowModal(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const passwordreset = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    // Encrypt the passwords
    // const encryptedOldPassword = encryptPassword(oldPassword);
    // const encryptedNewPassword = encryptPassword(newPassword);
    const body = {
      current_password: oldPassword,
      updated_password: newPassword,
    };
    // console.log(body);
    axios
      .put(ENDPOINTS.ONBOARDING.update_password, body, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        handleModalClose();
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        //Todo: move this logic to some util/component
        setTimeout(() => {
          setShowSuccessMessage(true);
          setMessage(response.data.message);
          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);
        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
        // console.error("Error adding user:", error);
      });
  };

  return (
    <div
      className="container-fluid position-relative z-index-1 pb-5"
      style={{top: "58px"}}>
      {showSuccessMessage && (
        <div className="d-flex justify-content-center mt-2">
          <div className="alert alert-success p-2 m-0 fs-5" role="alert">
            {Message}
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="container d-flex justify-content-center">
        <div className="card profile-card text-center mt-4 shadow-lg p-3 bg-body rounded">
          <div className="card-body text-light">
            <div className="d-flex justify-content-center mb-3">
              <img
                 src={user.logo_url ? user.logo_url : whiteLabel.MktgAI_Icon }
                alt="client logo"
                width="100"
                height="100"
                className="img-fluid rounded-circle p-1"
              />
            </div>
            <h2 className="card-title fw-bold">{user.name}</h2>
            <p className="mb-2">Role: {formatKeys(user.user_role)}</p>
            <p className="mb-3">Client: {user.client_name}</p>
            <button className="btn btn-primary shadow-sm" onClick={handleModalOpen}>
              Reset Password
            </button>
          </div>
        </div>
      </div>      
      )}
      {/* Password Reset Modal */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={passwordreset}>
            <div className="modal-body">
              <div className="mb-3">
                <label
                  htmlFor="current-password"
                  className="form-label d-flex justify-content-start text-dark">
                  Current Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="current-password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter current password"
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="new-password"
                  className="form-label d-flex justify-content-start text-dark">
                  New Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                  required
                />
              </div>
              <div className="mb-3">
              <label
                htmlFor="confirm-password"
                className="form-label d-flex justify-content-start text-dark">
                Confirm New Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm password"
                required
              />
              {/* Error message if passwords don't match */}
              {confirmPassword && newPassword !== confirmPassword && (
                <p className="text-danger mt-2">Passwords do not match!</p>
              )}
            </div>
              {showErrorMessage && (
                <div className="d-flex justify-content-center mt-2">
                  <p className="text-danger mt-2"> {Message}</p>
                </div>
              )}
            </div>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleModalClose}>
                Close
              </button>
              <button
              type="submit"
              className={`btn btn-primary ${
                newPassword !== confirmPassword ? "disabled" : ""
              }`}
              disabled={newPassword !== confirmPassword}>
              Reset
            </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
