import config from "../config.json";

const whiteLabelAssets = {
    "MktgAILogo": "MktgAILogo.png",
    "MktgAI_Icon": "MktgAI_Icon.png",
    "BG_login": "BG_login.png",
    "TradeDesk_logo": "Tradedesk_logo.png",
    "TradeDesk_img": "TradeDesk_img.png",
    "alt_image": "alt_image.png",
    "alt_logo": "alt_logo.png",
    "audio_thumbnail": "audio_thumbnail.png",
    "google_thumbnail": "google_thumbnail.png",
    "google_ads": "google-ads.svg"
};

// Determine the asset base URL based on the serviceUrl
const assetBaseUrl = config.serviceUrl === "https://backend.mktg.ai"
    ? config.assetBaseUrls.prod
    : config.assetBaseUrls.dev;

const whiteLabel = {};
for (const key in whiteLabelAssets) {
    whiteLabel[key] = `${assetBaseUrl}${whiteLabelAssets[key]}`;
}

export default whiteLabel;